<template>
  <b-card>
    <h3>Transfer BCA Balance</h3>
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="8">
          <b-row>
            <b-col sm="12">
              <b-form-group
                label="Wallet to"
                label-for="wallet-to"
              >
                <b-form-input
                  v-model="walletTo"
                  placeholder="0x..."
                  name="wallet-to"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group
                label="Value in wei"
                label-for="value-bnb"
              >
                <b-form-input
                  v-model="valueTo"
                  type="number"
                  name="value-bnb"
                  placeholder="1 = 1^18"
                />
              </b-form-group>
            </b-col>
            <!-- alert -->
            <b-col
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Remember to check that the selected Blockchain is correct in profile section.
                </h4>
              </b-alert>
            </b-col>
            <!--/ alert -->

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                disabled
              >
                Transfer
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="4">
          <b-row>
            <b-col sm="12" style="text-align: center;">
              <b-badge
                style="margin-bottom:15px; margin-top:20px"
                class="profile-badge"
                variant="light-primary"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your balance&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </b-badge>
            </b-col>
            <b-col sm="12" style="text-align: center;">
              <BcaBalance :center="true" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BBadge, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard,
} from 'bootstrap-vue'
import BcaBalance from '@/@core/layouts/components/BcaBalance.vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BBadge,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BcaBalance,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      walletTo: '',
      valueTo: '',
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
