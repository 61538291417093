<template>
  <b-card
    img-alt="User Profile"
    img-top
    class="card-profile"
  >
    <h5>Make a deposit</h5>
    <b-badge
      class="profile-badge"
      variant="light-primary"
    >
      Scan QR with your wallet or<br>
      use a secure copy button
    </b-badge>
    <hr
      class="mb-2"
      value="Or"
    >
    <qrcode-vue
      :value="walletaddress"
      size="200"
      level="H"
    />
    <!-- follower projects rank -->
    <div class="text-center">
      QR
    </div>
    <b-badge
      class="profile-badge"
      variant="light-primary"
    >
      Copy address
    </b-badge>
    <div
      style="text-align: center;"
    >
      <b-button
        variant="light-primary"
        style="color:#5d57bd"
        @click="copyAddress()"
      >
        <feather-icon icon="CopyIcon" />&nbsp;
      </b-button>
    </div>
    <input
      id="addressStr"
      type="hidden"
      :value="walletaddress"
    >
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BButton,
} from 'bootstrap-vue'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    QrcodeVue,
  },
  props: [
    'walletaddress',
  ],
  data() {
    return {
    }
  },
  methods: {
    numberInFormat: (inNumber, decimales, unitDecial, unitThousant) => {
      let number
      if (inNumber != null) {
        number = parseFloat(inNumber.toString().replace(/,/gi, ''))
      } else {
        number = Number.NaN
      }
      if (Number.isNaN(number)) {
        return ''
      }

      if (decimales !== undefined) {
        // Redondeamos
        number = number.toFixed(decimales)
      }

      // Convertimos el punto en unitDecial
      number = number.toString().replace('.', unitDecial !== undefined ? unitDecial : ',')

      if (unitThousant) {
        // Añadimos los separadores de miles
        const miles = new RegExp('(-?[0-9]+)([0-9]{3})')
        while (miles.test(number)) {
          number = number.replace(miles, `$1${unitThousant}$2`)
        }
      }
      return number
    },
    copyAddress () {
      let copy = document.querySelector('#addressStr')
      copy.setAttribute('type', 'text')
      copy.select()

      try {
        document.execCommand('copy')
        /* unselect the range */
        copy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'La direccion se ha copiado correctamente',
            variant: 'success',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
      } catch (err) {
        copy = null
      }
    },
  },
}
</script>
