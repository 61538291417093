<template>
  <b-card
    class="earnings-card"
    style="top: 1px; padding: 0px 0px; border: 1px #502f6d solid;"
  >
    <b-card-title class="mb-1">
      SWAP BNB/BCA
    </b-card-title>
    <b-row>
      <b-col
        cols="12"
        style="padding: 10px;"
      >
        <div class="font-small-2">
          Enter BNB amount to SWAP
        </div>
        <h5 class="mb-1">
          <logo-bnb
            v-show="currentNetworkId.id===56"
            style="width:20px"
          />&nbsp;
          <svg
            v-show="currentNetworkId.id===137"
            style="width: 24px; height: 24px; background: radial-gradient(white 60%, rgba(255, 255, 255, 0) calc(70% + 1px)); border-radius: 50%; box-shadow: white 0px 0px 1px;"
            width="1024"
            height="1024"
            viewBox="0 0 1024 1024"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="512"
              cy="512"
              r="512"
              fill="#8247E5"
            />
            <path
              d="M681.469 402.456C669.189 395.312 653.224 395.312 639.716 402.456L543.928 457.228L478.842 492.949L383.055 547.721C370.774 554.865 354.81 554.865 341.301 547.721L265.162 504.856C252.882 497.712 244.286 484.614 244.286 470.325V385.786C244.286 371.498 251.654 358.4 265.162 351.256L340.073 309.581C352.353 302.437 368.318 302.437 381.827 309.581L456.737 351.256C469.018 358.4 477.614 371.498 477.614 385.786V440.558L542.7 403.646V348.874C542.7 334.586 535.332 321.488 521.824 314.344L383.055 235.758C370.774 228.614 354.81 228.614 341.301 235.758L200.076 314.344C186.567 321.488 179.199 334.586 179.199 348.874V507.237C179.199 521.525 186.567 534.623 200.076 541.767L341.301 620.353C353.582 627.498 369.546 627.498 383.055 620.353L478.842 566.772L543.928 529.86L639.716 476.279C651.996 469.135 667.961 469.135 681.469 476.279L756.38 517.953C768.66 525.098 777.257 538.195 777.257 552.484V637.023C777.257 651.312 769.888 664.409 756.38 671.553L681.469 714.419C669.189 721.563 653.224 721.563 639.716 714.419L564.805 672.744C552.525 665.6 543.928 652.502 543.928 638.214V583.442L478.842 620.353V675.125C478.842 689.414 486.21 702.512 499.719 709.656L640.944 788.242C653.224 795.386 669.189 795.386 682.697 788.242L823.922 709.656C836.203 702.512 844.799 689.414 844.799 675.125V516.763C844.799 502.474 837.431 489.377 823.922 482.232L681.469 402.456Z"
              fill="white"
            />
          </svg>
          <b-form-input
            id="bnbAmount"
            v-model="tokenToSwapValue"
            name="amount"
            placeholder="1"
            autofocus
            type="number"
            @change="calculeRateBnbToBCASwap"
            @blur="calculeRateBnbToBCASwap"
          />
          <b-button
            variant="outline-secondary"
            style="margin-top:15px; margin-right: 3px; font-size: 8px;"
            @click="swapInUsd(100)"
          >
            100 USD
          </b-button>
          <b-button
            variant="outline-secondary"
            style="margin-top:15px; margin-right: 3px; font-size: 8px;"
            @click="swapInUsd(500)"
          >
            500 USD
          </b-button>
          <span
            style="float:right;font-size:8px;margin-top:4px;"
          >
            <span>{{ usdtSwapInfo }}</span> USDT
          </span>
        </h5>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#a8cf41"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ><line
          x1="12"
          y1="5"
          x2="12"
          y2="19"
        /><polyline points="19 12 12 19 5 12" /></svg>
        <div class="font-small-2">
          You earn BCA
        </div>
        <h5 class="mb-1">
          <img
            style="width:25px"
            :src="require('@/assets/images/token/coin-logo.png')"
          >
          <b-form-input
            id="bcaAmmount"
            v-model="earnBcaSwapValue"
            name="earn"
            placeholder="1"
            readonly
            type="number"
          />
        </h5>
        <b-button
          variant="outline-secondary"
          class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
          style="background: #a6ce3f; color: black;"
          @click="signSwap()"
        >
          SWAP
        </b-button>
      </b-col>
    </b-row>

    <hr class="mb-1">
    <b-row>
      <b-col cols="12">
        <b-card-text class="text-muted font-small-2">
          <span> Please verify that your account has a balance <span class="font-weight-bolder">BNB</span> before continuing.</span>

          <!-- <div
            class="justify-content-between align-items-center"
          >
            <b-button
              variant="light-primary"
              style="color:#5d57bd"
              @click="goToProjects()"
            >
              <feather-icon icon="TrelloIcon" />&nbsp;
              View Cannabis Projects
            </b-button>
            <b-button
              variant="light-primary"
              style="color:#5d57bd"
              @click="goToCyptoAssets()"
            >
              <feather-icon icon="BriefcaseIcon" />&nbsp;
              My Crypto Assets
            </b-button>
            <b-button
              variant="light-primary"
              style="color:#5d57bd"
              @click="gotohomecannaverse()"
            >
              <feather-icon icon="ExternalLinkIcon" />&nbsp;
              Explore the Cannaverse and trade NFTS
            </b-button>
            <b-button
              variant="light-primary"
              style="color:#5d57bd"
              @click="gotowalletbca()"
            >
              <feather-icon icon="ExternalLinkIcon" />&nbsp;
              Wallet BCA
            </b-button>
          </div> -->

        </b-card-text>
      </b-col>
    </b-row>
    <!-- loading modal -->
    <b-modal
      id="modal-swap-loading"
      centered
      ok-only
      hide-footer
      ok-disabled
      no-close-on-backdrop
      title="Transaction in progress"
    >
      <b-col>
        <b-progress
          animated
          centered
          :value="swapProgressCount"
          variant="success"
          class="'progress-bar-success'"
        />
        <span
          class="text-warning"
        >Waiting for decentralized exchange...</span>
      </b-col>
    </b-modal>
    <!-- loading Password -->
    <b-modal
      id="modal-swap-password"
      centered
      ok-only
      no-close-on-backdrop
      title="Sign Transaction"
      @ok="signTransaction"
      @hide="varClean"
    >
      <b-col>
        <table
          style="#363663; width:100%"
        >
          <tr>
            <td colspan="2">
              <b-form-group>
                <label>Please enter your pin code / password</label>
                <b-form-input
                  v-model="pinWalletTransaction"
                  type="password"
                  placeholder="Write your PIN code"
                />
              </b-form-group>
            </td>
          </tr>
        </table>
      </b-col>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton, BFormInput, VBModal, BProgress,
} from 'bootstrap-vue'
import logoBnb from '@core/layouts/components/logoBnb.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import contracts from '../libs/contract'
// import vSelect from 'vue-select'

export default {
  directives: {
    'v-modal': VBModal,
  },
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    // vSelect,
    BFormInput,
    logoBnb,
    BProgress,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // Congif Swap
      tokenToSwapValue: 1,
      earnBcaSwapValue: 0,
      bnbUsdtPrice: 0,
      usdtSwapInfo: 0,
      pinWalletTransaction: '',
      swapProgressCount: 0,
      doMetamaskRequest: false,
      dexContract: null,

      currentNetworkId: {
        id: 0,
      },

    }
  },
  async created() {
    this.usrData = this.$sessionData.get()
    this.currentNetworkId = this.$sessionData.getCurrentNetworkData()
    this.getBnbUsdtPrice()
    this.swapInUsd(100)
    // inicializar contrato si se ingreso con metamask
    if (this.usrData.metamaskSigin) {
      this.doMetamaskRequest = true
      this.contractsDeployed = await contracts.init()
        .catch(() => false)
      // si devolvieron datos, asignar el contrato de venta de plantas
      if (this.contractsDeployed) {
        this.dexContract = this.contractsDeployed.dexContract
      }
    }
  },
  methods: {

    showLoading() {
      this.$bvModal.show('modal-swap-loading')
    },
    hideLoading() {
      this.$bvModal.hide('modal-swap-loading')
    },
    showNeedPassword() {
      this.$bvModal.show('modal-swap-password')
    },
    hideNeedPassword() {
      this.$bvModal.hide('modal-swap-password')
    },
    signTransaction() {
      if ((!this.pinWalletTransaction !== '' && this.pinWalletTransaction.length > 4) || this.doMetamaskRequest) {
        this.swap()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Invalid password, please try again',
            variant: 'warning',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
      }
    },
    varClean() {
      this.pinWalletTransaction = ''
    },
    signSwap() {
      if (!this.doMetamaskRequest) {
        this.showNeedPassword()
      } else {
        this.signTransaction()
      }
    },
    singSwapOnHide() {
      this.signTransaction()
    },
    async swap() {
      if (this.tokenToSwapValue <= 0) return false
      if (this.currentNetworkId.id !== 56) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Inaviable network for swap',
            variant: 'success',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
      }
      this.showLoading()
      if (this.doMetamaskRequest) {
        this.transaction = this.dexContract.methods
          .buy()
          .send({ value: window.web3.utils.toWei(this.tokenToSwapValue.toString(), 'ether'), from: this.contractsDeployed.metamaskAddress })
          .on('transactionHash', () => {
            this.msgTransaction = 'Pending transaction... please wait'
          })
          .on('receipt', () => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Transaction successful!...',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
            setTimeout(() => {
              this.hideLoading()
            }, 1000)
          })
          .on('error', () => {
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Transaction could by processed, please try again',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              this.hideLoading()
            }, 1500)
          })
      } else {
        await this.$http.post(`/swapbnbbca?nid=${this.currentNetworkId.id}`, {
          amount: this.tokenToSwapValue.toString(),
          publicKey: this.usrData.data.kyc.wallet,
          password: this.pinWalletTransaction,
        })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: 'Transaction successful!...',
                variant: 'success',
              },
            },
            {
              position: 'bottom-center',
              timeout: 5000,
            })
            setTimeout(() => {
              this.hideLoading()
            }, 1000)
          })
          .catch(() => {
            setTimeout(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'InfoIcon',
                  text: 'Transaction could by processed, please verify your BNB balance and try again',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-center',
                timeout: 5000,
              })
              this.hideLoading()
            }, 1500)
          })
      }
      return true
    },
    async swapInUsd(usdAmount) {
      await this.getBnbUsdtPrice()
      this.tokenToSwapValue = (usdAmount / this.bnbUsdtPrice).toFixed(4)
      this.calculeRateBnbToBCASwap()
    },
    // Calcular los BCAs segun los BNB asignados
    async calculeRateBnbToBCASwap() {
      if (this.tokenToSwapValue <= 0) return false
      await this.getBnbUsdtPrice()
      this.usdtSwapInfo = (this.tokenToSwapValue * this.bnbUsdtPrice).toFixed(2)
      this.earnBcaSwapValue = (this.usdtSwapInfo / 1.1).toFixed(4)
      return true
    },
    getBnbUsdtPrice() {
      return new Promise((resolve, reject) => {
        let usdValue = null
        this.$http.get(`/wallet/equivalentUnitUsd?nid=${this.currentNetworkId.id}`)
          .then(usdPrice => {
            if (usdPrice) {
              usdValue = usdPrice.data.unitValue
            }
            if (!usdValue) {
              reject(new Error('Cannot get bnb price'))
              return
            }
            // 1 bnb to usd equivalent
            this.bnbUsdtPrice = parseFloat(usdValue)
            resolve()
          })
          .catch(() => false)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
input[type=number] {
  text-align:right;
}
</style>
