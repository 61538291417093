<template>
  <b-card
    img-alt="User Profile"
    img-top
    class="card-profile"
    style="top:2px; border: 1px #502f6d solid;"
  >
    <div
      class="profile-image-wrapper"
      style="top: -30px; position: relative;"
    >
      <div
        class="profile-image"
        style="background-color:#7367f0; padding: 2px;"
      >
        <div ref="imgsvg" />
      </div>
    </div>
    <br>
    <h3>{{ `${(this.$props.usrname)?this.$props.usrname : (userData ? userData.data.kyc.name: '') }` }} {{ `${(this.$props.usrlastname)?this.$props.usrlastname:this.userData.data.kyc.lastName}` }}</h3>
    <hr
      class="mb-1"
    >
    <b-form
      class="auth-login-form"
      @submit.prevent
    >
      <b-form-group
        v-show="!isZero"
        label-for="blockchain"
        label="Blockchain Network"
      >
        <v-select
          v-model="blockchainId"
          label="title"
          style="min-width:225px"
          :options="blockchains"
          @input="addNetwork()"
        >
          <template #option="{ title, icon }">
            <feather-icon
              :icon="icon"
              size="16"
              class="align-middle mr-50"
            />
            <span> {{ title }}</span>
          </template>
        </v-select>
      </b-form-group>
    </b-form>
    <div class="row w-full">
      <div
        class="col w-full"
        style="text-align:left"
      >

        <b-badge
          class="profile-badge"
          variant="light-primary"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your balance&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </b-badge>
        <div class="justify-content-between align-items-center">
          <BcaBalance />
          <br>
        </div>
      </div>
      <div
        class="col w-full"
        style="text-align:center"
      >
        <div class="justify-content-between align-items-center">
          <b-badge
            class="profile-badge"
            variant="light-primary"
          >
            KYC
          </b-badge>
          <br>
          <b class="section-label">Status:</b>&nbsp;
          <b-badge
            v-if="kycState == kycStates.noKyc"
            class="profile-badge"
            variant="light-warning"
          >
            Unverified
          </b-badge>
          <b-badge
            v-else-if="kycState == kycStates.pending"
            class="profile-badge"
            variant="light-warning"
          >
            Pending...
          </b-badge>
          <b-badge
            v-else-if="kycState == kycStates.incomplete"
            class="profile-badge"
            variant="light-warning"
          >
            Incomplete...
          </b-badge>
          <b-badge
            v-else
            class="profile-badge"
            variant="light-success"
          >
            Verified!
          </b-badge>
        </div>
        <!-- <b-button
          v-if="kycState == kycStates.noKyc"
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="outline-primary"
          :to="{name: 'kyc'}"
        >
          <feather-icon
            icon="AwardIcon"
            class="mr-50"
          />
          <span class="align-middle">Do KYC Now!</span>
        </b-button> -->
        <!-- <b-button
          v-if="kycState == kycStates.incomplete"
          variant="outline-primary"
          style="color:red"
          :to="{name: 'kyc'}"
        >
          <feather-icon
            icon="AwardIcon"
            class="mr-50"
          />
          <span class="align-middle">Complete KYC now!</span>
        </b-button> -->
      </div>
      <div class="col w-full">
        <div class="justify-content-between align-items-center">
          <b-badge
            style="margin-bottom: 0 !important"
            class="profile-badge"
            variant="light-primary"
          >
            &nbsp;Wallet Address&nbsp;
          </b-badge>
          <br>
          <b class="section-label">{{ ((walletAddress) ? walletAddress.substr(0,7): '')+'...'+((walletAddress) ? walletAddress.substr(35,walletAddress.length): '') }}</b>
          <b-button
            variant="light-primary"
            style="color:#797579"
            @click="copyWallet()"
          >
            <feather-icon icon="CopyIcon" />&nbsp;
            Copy
          </b-button>
          <input
            id="addressStr"
            type="hidden"
            :value="walletAddress"
          >
        </div>
        <div class="justify-content-between align-items-center">
          <b-badge
            style="margin-bottom: 0;"
            class="profile-badge"
            variant="light-primary"
          >
            &nbsp;Bancannabis User&nbsp;
          </b-badge>
          <br>
          <b class="section-label">No user assigned</b>
          <b-button
            variant="light-primary"
            style="color:#797579"
            @click="editBcaUser()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
          <input
            id="addressStr"
            type="hidden"
            :value="walletAddress"
          >
        </div>
      </div>
    </div>
    <hr
      class="mb-1"
    >
    <div
      class="justify-content-between align-items-center"
    >
      <b-button
        variant="light-primary"
        style="color:#797579"
        @click="openModalMakeDeposit()"
      >
        <feather-icon icon="MaximizeIcon" />&nbsp;
        Make a deposit
      </b-button>
      <!-- <b-button
        variant="light-primary"
        style="color:#5d57bd"
        @click="openBcaTokenPage()"
      >
        <feather-icon icon="ShoppingBagIcon" />&nbsp;
        Get tokens BCA
      </b-button> -->
      <!-- <b-button
        v-show="!isZero"
        variant="light-primary"
        style="color:#5d57bd"
        @click="goToProjects()"
      >
        <feather-icon icon="TrelloIcon" />&nbsp;
        Cannabis Companies
      </b-button>
      <b-button
        v-show="!isZero"
        variant="light-primary"
        style="color:#5d57bd"
        @click="goToCyptoAssets()"
      >
        <feather-icon icon="BriefcaseIcon" />&nbsp;
        My Crypto Assets
      </b-button> -->
      <!-- <b-button
        variant="light-primary"
        style="color:#5d57bd"
        @click="gotohomecannaverse()"
      >
        <feather-icon icon="ExternalLinkIcon" />&nbsp;
        Explore the Cannaverse and trade NFTS
      </b-button> -->
      <!-- <b-button
        v-show="!isZero"
        variant="light-primary"
        style="color:#5d57bd"
        @click="gotowalletbca()"
      >
        <feather-icon icon="ExternalLinkIcon" />&nbsp;
        Wallet BCA
      </b-button> -->
    </div>
    <!-- NFT detail modal -->
    <b-modal
      id="modal-deposit"
      centered
      ok-only
      title="Make a deposit"
      ok-title="Close"
    >
      <b-col>
        <card-deposit
          :walletaddress="walletAddress"
        />
      </b-col>
    </b-modal>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard, BBadge, BButton, BFormGroup, BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BcaBalance from '@/@core/layouts/components/BcaBalance.vue'
import CardDeposit from '@/views/cards/CardDeposit.vue'
import Ripple from 'vue-ripple-directive'
import { generateSvgAvatar } from '../../libs/pseudoramdom-avatar-generator'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BcaBalance,
    CardDeposit,
    vSelect,
    BFormGroup,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: [
    'bcabalance',
    'usdtbalance',
    'projectcount',
    'usrname',
    'usrlastname',
    'isWalletView',
  ],
  data() {
    const userDat = this.$sessionData.get()
    return {
      isZero: userDat.isZero,
      userData: userDat,
      metamaskSign: userDat.metamaskSigin,
      avatar: null,
      walletAddress: userDat.data.kyc.wallet,
      kycStates: {
        noKyc: 0,
        pending: 1,
        verify: 2,
        incomplete: 3,
      },
      kycState: 0,
      usrData: null,
      blockchainName: null,
      blockchainId: {
        title: 'Binance (BSC) Mainnet',
        icon: 'BoxIcon',
        id: 0x38,
        name: 'Binance Coin',
        provider: 'https://bsc-dataseed.binance.org/',
        scanner: 'https://bscscan.com',
        symbol: 'BNB',
      },
      // {
      //   title: 'GANACHE Mainnet',
      //   icon: 'BoxIcon',
      //   id: 5777,
      //   name: 'GANACHE',
      //   provider: 'HTTP://127.0.0.1:7545',
      //   scanner: '',
      //   symbol: 'BNB',
      // },
      blockchains: [
        {
          title: 'Binance (BSC) Mainnet',
          icon: 'BoxIcon',
          id: 0x38,
          name: 'Binance Coin',
          provider: 'https://bsc-dataseed.binance.org/',
          scanner: 'https://bscscan.com',
          symbol: 'BNB',
        },
        // {
        //   title: 'Binance Smart Chain (TESTNET)',
        //   icon: 'BoxIcon',
        //   id: 0x61,
        //   name: 'Binance Coin',
        //   provider: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        //   scanner: 'https://bscscan.com',
        //   symbol: 'BNB',
        // },
        // {
        //   title: 'CELO Mainnet',
        //   icon: 'BoxIcon',
        //   id: 0xA4EC,
        //   name: 'CELO',
        //   provider: 'https://celo-mainnet.infura.io',
        //   scanner: 'https://celoscan.io',
        //   symbol: 'CELO',
        // },
        // {
        //   title: 'GANACHE Mainnet',
        //   icon: 'BoxIcon',
        //   id: 5777,
        //   name: 'GANACHE',
        //   provider: 'HTTP://127.0.0.1:7545',
        //   scanner: '',
        //   symbol: 'BNB',
        // },
      ],
    }
  },
  created() {
    this.usrData = this.$sessionData.get()
    this.blockchainId = this.$sessionData.getCurrentNetworkData()
    if (!this.blockchainId.name) {
      this.logout()
    }
    return {
      kycStates: {
        noKyc: 0,
        pending: 1,
        verify: 2,
        incomplete: 3,
      },
    }
  },
  mounted() {
    if (this.userData.data.invitation.wallet) {
      this.walletAddress = this.userData.data.invitation.wallet
    } else {
      this.walletAddress = this.userData.data.kyc.wallet
    }
    const newAvatar = generateSvgAvatar(this.walletAddress)
    newAvatar.classList.add('badge-light')
    newAvatar.classList.add('rounded-circle')
    newAvatar.style.backgroundColor = 'unset'
    this.$refs.imgsvg.appendChild(newAvatar)
    // Get userdata
    this.usrData = this.$sessionData.get()
    // Si la cuenta ya esta verificada
    if (this.usrData.data.kyc.accountActive !== '0') {
      this.kycState = this.kycStates.verify
    }
    // verificamos el estado pendiente (en caso de que solo haya llenado los datos dle formulario pero no adjuntado las imagenes)
    if ((!this.usrData.data.kyc.sourceDocument1 || !this.usrData.data.kyc.sourceDocument2) && this.usrData.data.kyc.name !== '') {
      this.kycState = this.kycStates.incomplete
    }
    // si ingreso todos los datos e imagenes pero aun no se ha verificado
    if (this.usrData.data.kyc.name !== '' && (this.usrData.data.kyc.sourceDocument1 && this.usrData.data.kyc.sourceDocument2) && this.usrData.data.kyc.accountActive === '0') {
      this.kycState = this.kycStates.pending
    }
  },
  methods: {
    openModalMakeDeposit() {
      this.$bvModal.show('modal-deposit')
    },
    logout() {
      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    addNetwork() {
      // if (this.doMetamaskRequest) {
      //   return this.addNetworkToMetamask()
      // }
      // if (this.$sessionData.getCurrentNetworkData() === this.blockchainId.id) return false

      // actualizar la red en login bca
      this.$sessionData.setNetwork(this.blockchainId.id)
      window.location.reload()
      // this.$router.push({ name: 'login' })
      // this.$router.push({ name: '/' })
      return false
    },
    copyWallet () {
      let copy = document.querySelector('#addressStr')
      copy.setAttribute('type', 'text')
      copy.select()

      try {
        document.execCommand('copy')
        /* unselect the range */
        copy.setAttribute('type', 'hidden')
        window.getSelection().removeAllRanges()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text: 'Wallet address copy successful',
            variant: 'success',
          },
        },
        {
          position: 'bottom-center',
          timeout: 5000,
        })
      } catch (err) {
        copy = null
      }
    },
    openBcaTokenPage() {
      window.open('https://bcatoken.com', '_blank')
    },
    /*
    closeModalMakeDeposit() {
      this.$bvModal.hide('modal-deposit')
    },
    */
    gotohomecannaverse() {
      window.open('http://cannaverso.online', '_blank')
    },
    goToCyptoAssets() {
      this.$router.push({ name: 'my-genetic' })
    },
    goToProjects() {
      this.$router.push({ name: 'e-project-list' })
    },
    gotowalletbca() {
      this.$router.push({ name: 'wallet' })
    },
  },
}
</script>
