<template>
  <div>
    <!-- <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-secondary"
      style="cursor:pointer;"
      class="mb-1"
      @click="back"
    >
      Back
    </b-button> -->
    <b-tabs
      content-class="col-12 col-md-12 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-12 col-12"
      nav-class="nav-left"
    >
      <!-- general tab -->
      <b-tab active>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="CreditCardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profile</span>
        </template>
        <card-advance-profile
          :projectcount="1"
          :usdtbalance="0"
          :bcabalance="0"
          :usrname="usrName"
          :usrlastname="usrLastName"
          :kycstate="kycState"
          :is-wallet-view="true"
          class="mt-1"
        />
      </b-tab>

      <!-- Send Assets -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="SendIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Send</span>
        </template>

        <account-setting-general
          v-if="options.accountSetting"
          :general-data="options.accountSetting.general"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="PocketIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">P2P</span>
        </template>

        <bca-p2p />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="RefreshCwIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">BNB / BCA</span>
        </template>

        <Swap />
      </b-tab>

      <!-- KYC -->
      <b-tab>

        <!-- title -->
        <template #title>
          <feather-icon
            icon="UserCheckIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">KYC</span>
        </template>

        <kyc-wizard />
      </b-tab>

      <!-- Inventory -->
      <!-- <b-tab>

        <template #title>
          <feather-icon
            icon="UserCheckIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Inventory</span>
        </template>

        <user-inventory />
      </b-tab> -->
    </b-tabs>

  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  // BButton,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import Swap from '@/views/BcaSwap.vue'
import CardAdvanceProfile from '@/views/cards/CardAdvanceProfile.vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import KycWizard from '../forms/kyc/KycWizard.vue'
import BcaP2p from '../BcaP2P.vue'
// import UserInventory from '../genetic-view/list/List.vue'

const imgSmall = require('@/assets/images/portrait/small/avatar-s-9.jpg')

export default {
  components: {
    AccountSettingGeneral,
    CardAdvanceProfile,
    KycWizard,
    BcaP2p,
    // UserInventory,
    // BButton,
    BTabs,
    BTab,
    Swap,
  },
  directives: {
    Ripple,
  },
  data() {
    const data = {
      accountSetting: {
        general: {
          avatar: imgSmall,
          username: 'johndoe',
          fullName: 'John Doe',
          email: 'granger007@hogward.com',
          company: 'Crystal Technologies',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              id: 'johndoe',
            },
            google: {
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
    }
    return {
      options: data,
      usrName: '',
      usrLastName: '',
      kycState: 0,
    }
  },
  methods: {
    back() {
      const usrData = this.$sessionData.get()
      this.$router.push({ name: '/', params: { iz: usrData.isZero } })
    },
  },
  // beforeCreate() {
  //   // this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  // },
}
</script>
