<template>
  <div>
    <div
      v-if="!this.$props.onlybca"
      class="row w-full"
    >
      <div
        class="col w-full"
        :style="{'text-align':this.$props.center?'center' : 'left'}"
      >
        <span>
          <logo-bnb
            v-if="!isZero"
            style="width:20px"
          />&nbsp;
          <logo-celo v-if="isZero" />&nbsp;
          <!--
            <img
              style="width:30px"
              :src="require('@/assets/images/token/eth-logo-eth.png')"
            >
          -->
          <span
            class="font-semibold"
            style="font-size:12px"
          >{{ ethBalance }} {{ currentNetworkId.symbol }}</span> <br>
          <span
            class="font-semibold"
            style="font-size:8px; margin-left: 29px;"
          >{{ usdEquivalent }} USD</span>
          <b-spinner
            v-show="loadingBalance"
            variant="primary"
            label="Loading your Balance"
            small
          />
        </span>
      </div>
    </div>
    <div
      class="row w-full"
    >
      <div
        class="col w-full mt-1"
        :style="{'text-align':this.$props.center?'center' : 'left'}"
      >
        <span class="i18n-locale">
          &nbsp;
          <img
            v-if="!isZero"
            style="width:20px"
            :src="require('@/assets/images/token/coin-logo.png')"
          >
          <logo-zero v-if="isZero" />&nbsp;
          <span
            class="font-semibold"
            style="font-size:12px"
          >&nbsp; {{ tokenCount }} <span v-if="!isZero">BCA</span><span v-if="isZero">ZRZ</span></span><br>
        </span>
        <span
          v-if="!this.$props.onlybca"
          style="font-size:8px"
          class="font-semibold"
        >{{
          tokenCount ? ((tokenCount.includes(','))?
            (parseFloat(tokenCount.replace(',', ''))*1.1).toFixed(2)
            : (tokenCount*1.1).toFixed(2)) : 0
        }} USD</span>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import logoBnb from '@core/layouts/components/logoBnb.vue'
import logoCelo from '@core/layouts/components/logoCelo20x20.vue'
import logoZero from '@core/layouts/components/logoZero20x20.vue'

export default {
  components: {
    BSpinner,
    logoBnb,
    logoCelo,
    logoZero,
  },
  props: [
    'onlybca',
    'center',
  ],
  data() {
    return {
      tokenCount: 0,
      usdEquivalent: 0,
      ethBalance: 0,
      bnbToUsd: 0,
      tokenCountNoFormat: 0,
      plantsOwnerCount: 0,
      userData: null,
      loadingBalance: false,
      currentNetworkId: null,
      isZero: false,
    }
  },
  created() {
    this.userData = this.$sessionData.get()
    this.isZero = this.userData.isZero
    this.currentNetworkId = this.$sessionData.getCurrentNetworkData()
    this.getBcaBalance()
  },
  methods: {
    async getBcaBalance() {
      return new Promise(() => {
        try {
          if (this.userData.data.invitation.wallet !== '') {
            this.loadBalanceEthWallet()
          }
        } catch (err) {
          // reject(err)
        }
      })
    },
    formato_numero(numero, decimales, separadordecimal, separadormiles) {
      let nOut
      if (numero !== null) {
        nOut = parseFloat(numero.toString().replace(/,/gi, ''))
      } else {
        nOut = Number.isNaN
      }
      if (nOut === Number.isNaN) {
        return ''
      }

      if (decimales !== undefined) {
        // Redondeamos
        nOut = nOut.toFixed(decimales)
      }

      // Convertimos el punto en separadordecimal
      nOut = nOut.toString().replace('.', separadordecimal !== undefined ? separadordecimal : ',')

      if (separadormiles) {
        // Añadimos los separadores de miles
        const miles = new RegExp('(-?[0-9]+)([0-9]{3})')
        while (miles.test(nOut)) {
          nOut = nOut.replace(miles, `$1${separadormiles}$2`)
        }
      }
      return nOut
    },
    // Obtener y actualizar el saldo de BCA y ETH
    async loadBalanceEthWallet() {
      this.loadingBalance = true
      const walletAddress = this.userData.data.invitation.wallet
      if (walletAddress !== 'undefined' && walletAddress !== undefined) {
        this.$http.defaults.headers['x-access-token'] = this.userData.token
        const tokenBalance = await this.$http.get(`/wallet/${walletAddress}/bcabalance?nid=${this.currentNetworkId.id}&iz=${this.isZero ? 1 : 0}`).catch(() => false)
        if (tokenBalance) this.tokenCount = this.formato_numero(tokenBalance.data.balance, 2, '.', ',')

        if (walletAddress !== 'undefined') {
          const ethBalance = await this.$http.get(`/wallet/${walletAddress}/balance?nid=${this.currentNetworkId.id}`).catch(() => false)
          if (ethBalance) {
            if (ethBalance.data) this.ethBalance = (ethBalance.data.balance.length > 5) ? ethBalance.data.balance.substr(0, 6) : ethBalance.data.balance
          }
        }
      }
      this.convertBnbToUsd(this.ethBalance)
      this.$sessionData.setEthBalance(this.ethBalance)
      this.$balanceBCA = this.tokenCount
      this.loadingBalance = false
      setTimeout(() => {
        this.loadBalanceEthWallet()
      }, 60000)
    },
    calculeEthAmmountByUsd() {
      return new Promise((resolve, reject) => {
        let usdValue = null
        this.$http.get(`/wallet/equivalentUnitUsd?nid=${this.currentNetworkId.id}`)
          .then(usdPrice => {
            if (usdPrice) {
              usdValue = usdPrice.data.unitValue
            }
            if (!usdValue) {
              reject(new Error('Cannot get bnb price'))
              return
            }
            // usd to eth
            this.bnbToUsd = parseFloat(usdValue)
            resolve()
          })
          .catch(() => false)
      })
    },
    convertBnbToUsd(bnbAmmount) {
      try {
        this.calculeEthAmmountByUsd()
          .then(() => {
            this.usdEquivalent = this.formato_numero((this.bnbToUsd * parseFloat(bnbAmmount)), 2, '.', ',')
          })
      } catch {
        this.usdEquivalent = 0
      }
    },
  },
}
</script>
